<template>
    <div class="contenedor">

        <rutaPagina :volver="false" :ruta="ruta" />
        
        <div class="row glass"  >
            <tituloPagina texto="Información para filtrar" />
            <form class="row">
                <div class="col-lg-4 grupo-label">
                    <label for="filtro" class="form-label">Filtrar por</label>
                    <select id="filtro" class="form-select form-select seleccion" aria-label=".form-select-sm example">
                        <option value="0">Fecha factura</option>
                        <option value="1">Fecha vencimiento</option>
                        <option value="2">Fecha pago</option>
                    </select>
                </div>

                <div class="col-lg-4 grupo-label">
                    <label for="fecha_inicio" class="form-label">Fecha</label>
                    <div class="input-group mb-3">
                        <input type="date" id="fecha_inicio" class="form-control fecha-inicio" placeholder="Fecha inicio" aria-label="Fecha inicio">
                        <span class="input-group-text spam-hasta">hasta</span>
                        <input type="date" id="fecha_fin" class="form-control fecha-fin" placeholder="Fecha fin" aria-label="Fecha fin">
                    </div>
                </div>

                <div class="col-lg-4 grupo-label">
                    <label for="informacion_adiciona" class="form-label">Buscar dato</label>
                    <div class="input-group mb-3">                        
                        <input type="text" class="form-control input-buscar" placeholder="" aria-label="Username" aria-describedby="basic-addon1">
                        <button type="button" class="btn btn-primary input-group-text btn-buscar">Buscar</button>
                    </div>                        
                </div>
            </form>
        </div>

        <div class="row glass " >    
            <div class="contenedor-tabla">
                <div class="row" >
                    <div class="col-md-12 justificar-inicio" >
                        <a class="btn-exportar-excel"><i class="far fa-file-excel"></i>Exportar excel</a>
                    </div>
                </div>

                <div class="table-responsive">
                    <table id="tblPagosRealizados" class="dataTable tabla-ng">
                        <thead>
                            <tr class="omitir-bordes">
                                <th scope="col" ># factura</th>
                                <th scope="col" >Fecha</th>
                                <th scope="col" >Vencimiento</th>
                                <th scope="col" ># pago</th>
                                <th scope="col" >Valor</th>
                                <th scope="col" >Pagado</th>
                                <th scope="col" >Fecha pago</th>
                                <th scope="col" >Efectivo</th>
                                <th scope="col" >Transferencia</th>
                                <th scope="col" >Cheque</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="omitir-bordes">
                                <td>123456789</td>
                                <td>2021-10-04</td>
                                <td>2021-10-04</td>
                                <td>215652255</td>
                                <td>$ 5.000.000</td>
                                <td>$ 5.000.000</td>
                                <td>2021-10-04</td>
                                <td>$ 5.000.000</td>
                                <td>$ 0.0</td>
                                <td>$ 0.0</td>
                            </tr>  
                                                
                                                
                        </tbody>
                    </table>
                </div>                    
            </div>
        </div>

    </div>
</template>

<script>
import tituloPagina from '@/components/Titulopagina'
import { nextTick, onMounted } from "vue";
import $ from 'jquery'
import lenguajeDT from '@/assets/js/utilidades/traduccionDataTable.json'
import rutaPagina from '@/components/RutaPagina'

export default {
    name: 'Pagos',
    setup(props) {

        const cargarTabla = () => {
            nextTick(() => {
                $('#tblPagosRealizados').DataTable({
                    responsive: {
                        breakpoints: [
                        {name: 'bigdesktop', width: Infinity},
                        {name: 'meddesktop', width: 1480},
                        {name: 'smalldesktop', width: 1280},
                        {name: 'medium', width: 1188},
                        {name: 'tabletl', width: 1024},
                        {name: 'btwtabllandp', width: 848},
                        {name: 'tabletp', width: 768},
                        {name: 'mobilel', width: 480},
                        {name: 'mobilep', width: 320}
                        ]
                    },
                   "lengthMenu": [ [ 10, 25, 50, 75, 100, -1 ], [10, 25, 50, 75, 100, "Todos"] ],
                   "pageLength": 10,
                    language : JSON.parse(JSON.stringify(lenguajeDT))
                });
            });
        }

        const ruta = [
            { nombre : 'Estados de cuentas'},
            { nombre : 'Pagos efectuados'},
        ]

        onMounted(cargarTabla);

        return {
            cargarTabla,
            ruta,
        }
    },
    components : {
        tituloPagina,
        rutaPagina,
    }
}
</script>